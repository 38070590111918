<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('deliveryList') }}</h2>
      <grid-view-table
        :id="'delivery'"
        :columns="columns"
        :path="'driver'"
        :extended-filter-schema="extendedFilterSchema"
        :add-route="'AddDelivery'"
        :update-route="'UpdateDelivery'"
        :add-text="$t('addDelivery')"
      >
      </grid-view-table>
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import {columns} from './grid-views/delivery';
  import { schema as extendedFilterSchema } from './filter-forms-schemas/delivery';

  export default {
    name: 'DeliveryList',
    components: {
      GridViewTable,
    },
    data: () => {
      return {
        columns,
        extendedFilterSchema,
      }
    }
  }
</script>
